import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { filter, map, skip, takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';
import { DocumentViewerOptions } from '../../models/document-viewer-options.model';

import { LeaseAbstractStore } from '../../services/lease-abstract.store';

export enum TermExtractionResultVariant {
  Commencement = 'commencementExtractionResult',
  Expiration = 'expirationExtractionResult',
  Length = 'lengthExtractionResult',
  SquareFootage = 'squareFootageExtractionResult',
  OperatingExpenses = 'operatingExpensesExtractionResult',
  RealEstateTaxes = 'realEstateTaxesExtractionResult',
  SecurityDeposit = 'securityDepositExtractionResult',
  BaseRentalRate = 'baseRentalRateExtractionResult',
  FreeRent = 'freeRentExtractionResult',
  RentalRateEscalation = 'rentalRateEscalationExtractionResult',
  BuildingPercentage = 'buildingPercentageExtractionResult',
}

@Component({
  selector: 'app-import-form-term-extraction-result',
  templateUrl: 'import-form-term-extraction-result.component.html',
  styleUrls: ['import-form-term-extraction-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportFormTermExtractionResultComponent implements OnInit, OnDestroy {
  @Input() variant: keyof typeof TermExtractionResultVariant;

  @Input() documentViewerOptions: DocumentViewerOptions;
  @Input() documentViewerOptionsChange: Subject<DocumentViewerOptions>;

  @ViewChild('contentContainerElementRef', {static: false}) contentContainerElementRef: ElementRef<HTMLParagraphElement>;

  Variants: typeof TermExtractionResultVariant = TermExtractionResultVariant;

  extractionResults: Array<models.IExtractionResultSimplifiedView>;

  private readonly _leaseAbstractStore: LeaseAbstractStore;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _domSanitizer: DomSanitizer;
  private readonly _destroy: Subject<void>;

  constructor(
    leaseAbstractStore: LeaseAbstractStore,
    changeDetectorRef: ChangeDetectorRef,
    domSanitizer: DomSanitizer
  ) {
    this._leaseAbstractStore = leaseAbstractStore;
    this._changeDetectorRef = changeDetectorRef;
    this._domSanitizer = domSanitizer;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this._leaseAbstractStore
      .getTermExtractionResult()
      .pipe(
        filter(termExtractionResult => Boolean(termExtractionResult)),
        map(termExtractionResult => {
          const property = TermExtractionResultVariant[this.variant];
          if (!termExtractionResult.hasOwnProperty(property)) {
            throw new Error(`Unknown variant: ${property}`);
          }

          return termExtractionResult[property];
        }),
        tap(results => {
          this.extractionResults = results;
          this._changeDetectorRef.detectChanges();
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();

    this._changeDetectorRef.detach();
  }

  getHighlightedText(extractionResult: models.IExtractionResultSimplifiedView): SafeHtml {
    if (!extractionResult || !extractionResult.phrase || !extractionResult.phrase.tokens || !extractionResult.phrase.tokens.length) {
      return;
    }

    const tokens = extractionResult.phrase.tokens;

    const findSubArrayIndex = (needle: Array<models.ITokenSimplifiedView>): number => {
      loop: for (let i = 0; i <= tokens.length - needle.length; ++i) {
        for (let j = 0; j < needle.length; ++j) {
          if (tokens[i + j].rawValue !== needle[j].rawValue) {
            continue loop;
          }
        }

        return i;
      }

      return -1;
    };

    const indexes: {[key: string]: {position: [number, number], keys: Array<string>, rawString: string}} = {};

    for (const [key, value] of Object.entries(extractionResult.found ?? {})) {
      if (!value || !value.tokens || !value.tokens.length) {
        continue;
      }

      const subArray = value.tokens;
      const indexOfSubArray = findSubArrayIndex(subArray);
      if (indexOfSubArray < 0) {
        continue;
      }

      const firstIndexOfSubArray = indexOfSubArray;
      const lastIndexOfSubArray = indexOfSubArray + (subArray.length - 1);

      if (!indexes[`${firstIndexOfSubArray}:${lastIndexOfSubArray}`]) {
        indexes[`${firstIndexOfSubArray}:${lastIndexOfSubArray}`] = {
          position: [firstIndexOfSubArray, lastIndexOfSubArray],
          keys: [key],
          rawString: value.rawString,
        };

        continue;
      }

      indexes[`${firstIndexOfSubArray}:${lastIndexOfSubArray}`].keys.push(key);
    }

    const result = tokens.map(x => x.rawValue.padEnd(x.rawValue.length + x.numberOfTrailingSpaces, ' '));

    for (const index of Object.values(indexes)) {
      const [startOfIndex, endOfIndex] = index.position;
      const keysClass = index.keys.join(' ');
      const keysTitle = index.keys.join(', ');

      result[startOfIndex] = `<span class="found ${keysClass}" title="Tags: ${keysTitle}" data-text="${index.rawString}">${result[startOfIndex]}`;
      result[endOfIndex] = `${result[endOfIndex].trim()}</span>${''.padStart(tokens[endOfIndex].numberOfTrailingSpaces)}`;
    }

    return this._domSanitizer.bypassSecurityTrustHtml(result.join(''));
  }

  async copyToClipboard(extractionResult: models.IExtractionResultSimplifiedView): Promise<void> {
    if (!extractionResult || !extractionResult.found) {
      return;
    }

    const documentLanguage = extractionResult.found['documentLanguage'];
    if (!documentLanguage || !documentLanguage.rawString) {
      return;
    }

    await this._copyToClipboardAsync(documentLanguage.rawString);
  }

  navigateToContent(extractionResult: models.IExtractionResultSimplifiedView): void {
    if (!extractionResult || !extractionResult.phrase) {
      return;
    }

    let phrase: models.IPhraseNodeSimplifiedView = extractionResult.phrase;
    if (phrase.parts && phrase.parts.length) {
      phrase = phrase.parts[0];
    }

    const { pageNumber, boundingBox } = phrase;

    this.documentViewerOptions.page = pageNumber;
    this.documentViewerOptions.hashCode = boundingBox.hashCode;

    this.documentViewerOptionsChange.next(this.documentViewerOptions);
  }

  async handleDoubleClickOnContentAsync(event: MouseEvent): Promise<void> {
    const element = <HTMLElement>event.target;
    if (element.classList.contains('found') && element.dataset['text']) {
      await this._copyToClipboardAsync(element.dataset['text']);
    }
  }

  formatSection(section: string): string {
    const lowercase = section.toLowerCase();
    if (lowercase.startsWith('section')) {
      return section;
    }

    return `Section ${section}`;
  }

  getTable(extractionResult: models.IExtractionResultSimplifiedView): models.IPhraseNodeSimplifiedView[][] {
    return <any>extractionResult.table;
  }

  private async _copyToClipboardAsync(text: string): Promise<void> {
    if (!navigator) {
      return;
    }

    const permissions = await navigator.permissions?.query({ name: 'clipboard-write' });
    if (permissions.state !== 'granted') {
      console.error('No permissions to write on clipboard');
      return;
    }

    try {
      await navigator.clipboard?.writeText(text);
    } catch (error) {
      console.error(error);
    }
  }
}
