import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import CustomStore from 'devextreme/data/custom_store';

import { Tools } from '../../../../shared/tools';

import { PopupService } from '../../../infrastructure/services/popup.service';

import * as models from '../../../../shared/models/generated';

import { environment } from '../../../../../environments/environment';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';
import { EditLeaseUserDialogComponent } from '../edit-lease-user-dialog/edit-lease-user-dialog.component';

@Component({
  templateUrl: 'lease-user-list.component.html',
  styleUrls: ['lease-user-list.component.scss'],
})
export class LeaseUserListComponent implements OnInit {
  private static readonly _leaseUserListUrl = `${environment.adminUrl}/lease-users`;
  private static readonly _usersUrl = `${environment.adminUrl}/User/Get`;
  private static readonly _leasesUrl = `${environment.adminUrl}/Leases/shortLeaseModels`;
  private static readonly _companiesUrl = `${environment.adminUrl}/Company`;

  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;

  dataSource: CustomStore;
  userDataSource: CustomStore;
  leaseDataSource: CustomStore;
  companyDataSource: CustomStore;

  Tools = Tools;

  private readonly _popupService: PopupService;

  constructor(popupService: PopupService) {
    this._popupService = popupService;

    this.isAcceptButtonVisible = this.isAcceptButtonVisible.bind(this);
    this.acceptInvitation = this.acceptInvitation.bind(this);
    this.editLeaseUser = this.editLeaseUser.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: LeaseUserListComponent._leaseUserListUrl,
      updateUrl: LeaseUserListComponent._leaseUserListUrl,
      deleteUrl: LeaseUserListComponent._leaseUserListUrl,
    });

    this.userDataSource = AspNetData.createStore({
      loadUrl: LeaseUserListComponent._usersUrl,
    });

    this.leaseDataSource = AspNetData.createStore({
      loadUrl: LeaseUserListComponent._leasesUrl,
    });

    this.companyDataSource = AspNetData.createStore({
      loadUrl: LeaseUserListComponent._companiesUrl,
    });
  }

  onToolbarPreparing(event): void {
    if (event.toolbarOptions) {
      event.toolbarOptions.items = [
        ...(event.toolbarOptions.items || [])
      ];
    }
  }

  isAcceptButtonVisible(event): boolean {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    return !event.row.data.isInvitationAccepted;
  }

  acceptInvitation(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to accept the invitation?',
        acceptFn: () => {
          this.dataSource
            .update(event.row.data.id, null)
            .then(() => {
              if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
                const dataSource = this.dxDataGridComponent.instance.getDataSource();
                dataSource.reload();
              }
            });
        },
      },
    });
  }

  editLeaseUser(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this.showEditLeaseUserDialog(event.row.data);
  }

  getUserAvatar(item): string {
    if (!item || !item.data || !item.data.userProfile || !item.data.userProfile.avatar) {
      return 'assets/img/avatar.png';
    }

    return item.data.userProfile.avatar.url;
  }

  getUserDisplayName(item): string {
    if (!item || !item.data || !item.data.userProfile) {
      return '';
    }

    const { firstName, lastName } = item.data.userProfile;

    return [firstName, lastName]
      .filter(Boolean)
      .join(' ');
  }

  getRole(leaseUser: models.ILeaseUserViewModel): string {
    if (!leaseUser) {
      return null;
    }

    if (
      leaseUser.leaseUserRole !== models.LeaseUserRole.Broker &&
      leaseUser.leaseUserRole !== models.LeaseUserRole.CoBroker
    ) {
      return models.LeaseUserRole[leaseUser.leaseUserRole];
    }

    return `${models.LeaseUserRole[leaseUser.leaseUserRole]} of ${leaseUser.relatedToCompany.name}`;
  }

  getClaimsList(claims: Array<models.IStateraUserClaim>): string {
    const claimGroups = claims
      .filter(x => x.stateraClaimGroup && x.stateraClaimGroup.name)
      .map(x => x.stateraClaimGroup.name);

    const customClaims = claims
      .filter(x => x.stateraClaim && x.stateraClaim.claimType)
      .map(x => {
        const claimType = models.StateraClaimTypeAsEnum[x.stateraClaim.claimType];
        const claimValue = models.StateraClaimValueAsEnum[x.stateraClaim.claimValue];

        return [claimType, claimValue]
          .filter(Boolean)
          .join(': ');
      });

    return [...claimGroups, ...customClaims]
      .filter(Boolean)
      .join(', ');
  }

  private showEditLeaseUserDialog(leaseUser: models.ILeaseUserViewModel): void {
    this._popupService.show(EditLeaseUserDialogComponent, {
      title: 'Edit Lease User',
      width: 750,
      maxHeight: '90%',
      showCloseButton: true,
      closeOnOutsideClick: false,
      injectableData: {
        leaseUser,
        reloadUsersList: async () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            await dataSource.reload();
          }
        },
      }
    });
  }
}
