<ng-container *ngIf="extractionResults?.length">
  <div [ngClass]="['import-form-term-extraction-results', variant, Variants[variant]]">
    <div class="import-form-term-extraction-results-title">
      Extraction result
    </div>
    <div class="import-form-term-extraction-results-body">
      <ul>
        <li *ngFor="let result of extractionResults">
          <div class="import-form-term-extraction-result">
            <div class="import-form-term-extraction-result-content">
              <p class="import-form-term-extraction-result-content-extraction"
                 [innerHTML]="getHighlightedText(result)"
                 (dblclick)="handleDoubleClickOnContentAsync($event)"
                 #contentContainerElementRef>
              </p>

              <ng-container *ngIf="getTable(result) as table">
                <table>
                  <tr *ngFor="let row of table;">
                    <td *ngFor="let column of row;">
                      {{column.rawString}}
                    </td>
                  </tr>
                </table>
              </ng-container>

              <p class="import-form-term-extraction-result-content-meta">
                <span>Page {{result.phrase.pageNumber}}</span>
                <span *ngIf="result.found['section']?.rawString as section">{{formatSection(section)}}</span>
              </p>
            </div>
            <div class="import-form-term-extraction-result-actions">
              <button role="button" type="button" class="import-form-term-extraction-result-action"
                      title="Copy to clipboard"
                      (click)="copyToClipboard(result)">
                <i class="fa fa-clipboard" aria-hidden="true"></i>
              </button>

              <button role="button" type="button" class="import-form-term-extraction-result-action"
                      title="Navigate to content"
                      (click)="navigateToContent(result)">
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
