import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as dx from 'devextreme-angular';
import { BehaviorSubject, Observable, Subject, zip } from 'rxjs';
import { switchMap, takeUntil, tap, map } from 'rxjs/operators';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PlanService } from '../../services/plan.service';
import { PlanAnchorService } from '../../services/plan-anchor.service';
import { PlanAnchorImageMarkerService } from '../../services/plan-anchor-image-marker.service';

import { PlanViewerComponent } from '../../../plan-viewer/components/plan-viewer/plan-viewer.component';

import * as models from '../../../../shared/models/generated';
import * as imageViewerModels from '../../../image-viewer/models/image-viewer-image.model';
import * as planViewerModels from '../../../plan-viewer/models/plan-viewer.model';

interface TabItem {
  id: number;
  title: string;
}

@Component({
  templateUrl: './site-plan-manage.component.html',
  styleUrls: ['./site-plan-manage.component.scss']
})
export class SitePlanManageComponent implements OnInit, OnDestroy {
  @Input() sitePlanId: number;
  @Input() buildingId: number;
  @Input() reloadSitePlans: () => void;

  @ViewChild('planSelect', {static: false}) planSelect: dx.DxSelectBoxComponent;
  @ViewChild('unitMarkerAccordion', {static: false}) unitMarkerAccordion: dx.DxAccordionComponent;
  @ViewChild(PlanViewerComponent, {static: false}) planViewerComponent: PlanViewerComponent;

  buildingAreas: typeof models.BuildingAreaType;

  plans: Array<models.IPlanViewModel>;

  selectedPlan: models.IPlanViewModel;
  selectedAnchorId: number;

  anchorsTabItems: Array<TabItem>;
  anchorsTabIndex: number;

  planImageScale: number;

  popupContainerExpanded: boolean;

  get sitePlanImage(): planViewerModels.PlanViewerImage {
    if (!this.selectedPlan) {
      return null;
    }

    return {
      id: this.selectedPlan.id,
      imageUrl: this.selectedPlan.picture.url,
      angle: this.selectedPlan.angle,
      markers: this.selectedPlan
        .anchors
        .map(anchor => {
          let isAvailableSpace: boolean;
          if (anchor.buildingUnit && anchor.buildingUnit.listing) {
            isAvailableSpace = true;
          }
          return <planViewerModels.PlanViewerMarkerRef>{
            id: anchor.id,
            title: anchor.name,
            description: anchor.description,
            isAvailableSpace: isAvailableSpace,
            buildingUnitId: anchor.buildingUnitId,
            buildingUnit: anchor.buildingUnit,
            x: anchor.x,
            y: anchor.y,
            width: anchor.width,
            height: anchor.height,
            points: anchor.points,
            type: planViewerModels.PlanViewerMarkerType[planViewerModels.PlanViewerMarkerType[anchor.anchorType]],
            color: this.getMarkerColor(anchor),
            area: anchor.buildingArea,
            images: this.getSitePlanAnchorImages(anchor),
          };
        }),
    };
  }

  get unitAnchors(): Array<models.IPlanAnchorViewModel> {
    if (!this.selectedPlan) {
      return [];
    }
    return this
      .selectedPlan
      .anchors
      .filter(anchor => !!anchor.buildingUnitId);
  }

  get markerAnchors(): Array<models.IPlanAnchorViewModel> {
    if (!this.selectedPlan) {
      return [];
    }
    return this
      .selectedPlan
      .anchors
      .filter(anchor => !anchor.buildingUnitId);
  }

  readonly markerColorChange$: EventEmitter<{id: number, color: planViewerModels.PlanViewerMarkerColor}>;
  readonly markerCreationEnd$: EventEmitter<planViewerModels.PlanViewerMarkerRef>;

  private readonly _planService: PlanService;
  private readonly _planAnchorService: PlanAnchorService;
  private readonly _planAnchorImageMarkerService: PlanAnchorImageMarkerService;
  private readonly _popupRefService: PopupRefService;
  private readonly _changeDetectorRef: ChangeDetectorRef;

  private _destroy$: Subject<void>;

  constructor(
    planService: PlanService,
    planAnchorService: PlanAnchorService,
    planAnchorImageMarkerService: PlanAnchorImageMarkerService,
    popupRefService: PopupRefService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    this._planService = planService;
    this._planAnchorService = planAnchorService;
    this._planAnchorImageMarkerService = planAnchorImageMarkerService;
    this._popupRefService = popupRefService;
    this._changeDetectorRef = changeDetectorRef;

    this.markerColorChange$ = new EventEmitter<{id: number, color: planViewerModels.PlanViewerMarkerColor}>();
    this.markerCreationEnd$ = new EventEmitter<planViewerModels.PlanViewerMarkerRef>();
  }

  ngOnInit(): void {
    this._destroy$ = new Subject<void>();

    this.buildingAreas = models.BuildingAreaType;

    this.anchorsTabItems = [
      { id: 0, title: 'Units' },
      { id: 1, title: 'Markers' },
    ];
    this.anchorsTabIndex = 0;

    this.planImageScale = 100;

    this.plans = [];

    this._planService
      .getPlanList(models.PlanKind.Site, this.buildingId)
      .pipe(
        map(x => x.data),
        takeUntil(this._destroy$),
      )
      .subscribe(plans => {
        this.plans = plans;

        const selectedPlanIndex = plans
          .findIndex(plan => (
            plan.id === this.sitePlanId
          ));

        this.selectedPlan = plans[selectedPlanIndex];
      });

    // DxAccordionComponent incorrectly calculates its height due to popup opening animation.
    // We need to slightly delay its initialization.
    this.popupContainerExpanded = false;
    setTimeout(() => {
      this.popupContainerExpanded = true;
    }, 600);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  close(): void {
    this._popupRefService.hide();
  }

  getSuggestions(): Array<{ id?: number, name: string }> {
    switch (this.anchorsTabIndex) {
      case 0:
        return this
          .selectedPlan
          .building
          .buildingUnits;
      default:
        return [];
    }
  }

  getMarkerColor(anchor: models.IPlanAnchorViewModel): planViewerModels.PlanViewerMarkerColor {
    if (!anchor.buildingUnit) {
      return planViewerModels.PlanViewerMarkerColor.Blue;
    }

    return anchor.buildingUnit.listing ?
      planViewerModels.PlanViewerMarkerColor.White :
      planViewerModels.PlanViewerMarkerColor.Gray;
  }

  getSitePlanAnchorImages(anchor: models.IPlanAnchorViewModel): Array<planViewerModels.PlanViewerMarkerImageRef> {
    if (!anchor || !anchor.images || !anchor.images.length) {
      return [];
    }

    return anchor
      .images
      .map((anchorImage) => {
        return <planViewerModels.PlanViewerMarkerImageRef>{
          imageViewerImage: {
            id: anchorImage.id,
            imageUrl: anchorImage.image.url,
            imageKind: imageViewerModels.ImageViewerImageKind[
              imageViewerModels.ImageViewerImageKind[anchorImage.imageKind]
            ],
            markers: anchorImage
              .markers
              .map((anchorImageMarker) => {
                return <imageViewerModels.ImageViewerImageMarkerRef>{
                  id: anchorImageMarker.id,
                  x: anchorImageMarker.x,
                  y: anchorImageMarker.y,
                  text: anchorImageMarker.text,
                };
              }),
          },
        };
      });
  }

  handleAccordionSelectionChanged(e): void {
    if (!e.addedItems.length) {
      return;
    }

    const selectedAnchorIndex = this
      .selectedPlan
      .anchors
      .findIndex(anchor => (
        anchor.name === e.addedItems[0].title
      ));

    this.selectedAnchorId = this.selectedPlan.anchors[selectedAnchorIndex].id;
  }

  handleTabChange(tabItem: TabItem): void {
    if (!tabItem) {
      return;
    }

    this.selectedAnchorId = null;
    this.anchorsTabIndex = tabItem.id;
  }

  handleScaleChange(scale: number): void {
    this.planImageScale = scale;
    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  handlePlanMarkerSelect(markerId: number): void {
    this.selectedAnchorId = markerId;

    const unitAnchorIndex = this.unitAnchors.findIndex(anchor => anchor.id === markerId);
    if (unitAnchorIndex !== -1) {
      this.anchorsTabIndex = 0;

      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();

      this.unitMarkerAccordion.instance.expandItem(unitAnchorIndex);
    }

    const markerAnchorIndex = this.markerAnchors.findIndex(anchor => anchor.id === markerId);
    if (markerAnchorIndex !== -1) {
      this.anchorsTabIndex = 1;

      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();

      this.unitMarkerAccordion.instance.expandItem(markerAnchorIndex);
    }

    if (unitAnchorIndex === -1 && markerAnchorIndex === -1) {
      this.unitMarkerAccordion.instance.collapseItem(this.unitMarkerAccordion.selectedIndex);
    }
  }

  handlePlanMarkerCreate(markerRef: planViewerModels.PlanViewerMarkerRef): void {
    if (!this.selectedPlan) {
      return;
    }

    let observable: Observable<Array<models.IFileViewModel>> = new BehaviorSubject([]);
    if (markerRef.images && markerRef.images.length) {
      const images$ = [];
      for (let i = 0, num = markerRef.images.length; i < num; i++) {
        const image = markerRef.images[i];
        if (!image) {
          continue;
        }

        images$.push(this._planAnchorService.uploadPlanAnchorPicture(image.file));
      }

      observable = zip<Array<models.IFileViewModel>>(...images$);
    }

    observable
      .pipe(
        takeUntil(this._destroy$),
        switchMap((images: Array<models.IFileViewModel>) => {
          const anchor = <models.IPlanAnchorViewModel>{
            name: markerRef.title,
            description: markerRef.description,
            buildingArea: markerRef.area,
            planId: this.selectedPlan.id,
            buildingUnitId: markerRef.buildingUnitId,
            x: markerRef.x,
            y: markerRef.y,
            width: markerRef.width,
            height: markerRef.height,
            points: markerRef.points,
            anchorType: models.PlanAnchorType[models.PlanAnchorType[markerRef.type]],
            images: images.map(image => {
              return <models.IPlanAnchorImageViewModel>{
                image: image,
              };
            }),
          };

          return this._planAnchorService
            .createPlanAnchor(anchor)
            .pipe(
              tap((createdMarker: models.IPlanAnchorViewModel) => {
                markerRef.id = createdMarker.id;
                markerRef.images = this.getSitePlanAnchorImages(createdMarker);

                this.markerCreationEnd$.emit(markerRef);
                this.markerColorChange$.emit({ id: createdMarker.id, color: this.getMarkerColor(createdMarker) });

                if (this.selectedPlan) {
                  const sitePlanIndex = this.plans.findIndex(x => x.id === this.selectedPlan.id);
                  if (0 <= sitePlanIndex) {
                    if (!this.plans[sitePlanIndex].anchors) {
                      this.plans[sitePlanIndex].anchors = [];
                    }

                    this.plans[sitePlanIndex].anchors.push(createdMarker);

                    this.selectedPlan = this.plans[sitePlanIndex];
                  }
                }
              }),
            );
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  handlePlanMarkerChange(markerRef: planViewerModels.PlanViewerMarkerRef): void {
    if (!this.selectedPlan) {
      return;
    }

    const anchor = <models.IPlanAnchorViewModel>{
      id: markerRef.id,
      name: markerRef.title,
      description: markerRef.description,
      buildingArea: markerRef.area,
      planId: this.selectedPlan.id,
      buildingUnitId: markerRef.buildingUnitId,
      x: markerRef.x,
      y: markerRef.y,
      points: markerRef.points,
      width: markerRef.width,
      height: markerRef.height,
      anchorType: models.PlanAnchorType[models.PlanAnchorType[markerRef.type]],
    };

    this._planAnchorService
      .updatePlanAnchor(anchor)
      .pipe(
        switchMap(() => (
          this._planService
            .getPlanList(models.PlanKind.Site, this.buildingId)
        )),
        map(x => x.data),
        tap((plans) => {
          this.plans = plans;

          const selectedPlanIndex = plans
            .findIndex(plan => (
              plan.id === this.sitePlanId
            ));

          this.selectedPlan = plans[selectedPlanIndex];

          this.selectedAnchorId = null;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  handlePlanMarkerDelete(markerRef: planViewerModels.PlanViewerMarkerRef): void {
    if (!this.selectedPlan) {
      return;
    }

    const anchor = <models.IPlanAnchorViewModel>{
      id: markerRef.id,
      name: markerRef.title,
      description: markerRef.description,
      planId: this.selectedPlan.id,
      x: markerRef.x,
      y: markerRef.y,
      width: markerRef.width,
      height: markerRef.height,
      anchorType: models.PlanAnchorType[models.PlanAnchorType[markerRef.type]],
    };

    this._planAnchorService
      .deletePlanAnchor(anchor)
      .pipe(
        switchMap(() => (
          this._planService
            .getPlanList(models.PlanKind.Site, this.buildingId)
        )),
        map(x => x.data),
        tap((plans) => {
          this.plans = plans;

          const selectedPlanIndex = plans
            .findIndex(plan => (
              plan.id === this.sitePlanId
            ));

          this.selectedPlan = plans[selectedPlanIndex];

          this.selectedAnchorId = null;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  handleImageMarkerCreate(ref: planViewerModels.PlanViewerImageMarkerRef): void {
    if (!ref || !ref.markerRef || !ref.imageMarkerRef) {
      return;
    }

    const marker = <models.IPlanAnchorImageMarkerViewModel>{
      anchorImageId: ref.imageMarkerRef.imageRef.id,
      x: ref.imageMarkerRef.x,
      y: ref.imageMarkerRef.y,
      text: ref.imageMarkerRef.text,
    };

    this._planAnchorImageMarkerService
      .createPlanAnchorImageMarker(marker)
      .pipe(
        tap((createdMarker: models.IPlanAnchorImageMarkerViewModel) => {
          ref.imageMarkerRef.id = createdMarker.id;
          ref.imageMarkerRef.text = createdMarker.text;

          this._addSitePlanImageMarker(ref, createdMarker);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  handleImageMarkerChange(ref: planViewerModels.PlanViewerImageMarkerRef): void {
    if (!ref || !ref.markerRef || !ref.imageMarkerRef) {
      return;
    }

    const marker = <models.IPlanAnchorImageMarkerViewModel>{
      id: ref.imageMarkerRef.id,
      anchorImageId: ref.imageMarkerRef.imageRef.id,
      x: ref.imageMarkerRef.x,
      y: ref.imageMarkerRef.y,
      text: ref.imageMarkerRef.text,
    };

    this._planAnchorImageMarkerService
      .updatePlanAnchorImageMarker(marker)
      .pipe(
        tap((changedMarker: models.IPlanAnchorImageMarkerViewModel) => {
          ref.imageMarkerRef.text = changedMarker.text;

          this._updateSitePlanImageMarker(ref, changedMarker);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  handleImageMarkerDelete(ref: planViewerModels.PlanViewerImageMarkerRef): void {
    if (!ref || !ref.markerRef || !ref.imageMarkerRef) {
      return;
    }

    const marker = <models.IPlanAnchorImageMarkerViewModel>{
      id: ref.imageMarkerRef.id,
      anchorImageId: ref.imageMarkerRef.imageRef.id,
      x: ref.imageMarkerRef.x,
      y: ref.imageMarkerRef.y,
      text: ref.imageMarkerRef.text,
    };

    this._planAnchorImageMarkerService
      .deletePlanAnchorImageMarker(marker)
      .pipe(
        tap(() => {
          this._deleteSitePlanImageMarker(ref);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _addSitePlanImageMarker(
    ref: planViewerModels.PlanViewerImageMarkerRef,
    marker: models.IPlanAnchorImageMarkerViewModel,
  ): void {
    if (!this.selectedPlan) {
      return;
    }

    const planIndex = this.plans
      .findIndex(x => x.id === this.selectedPlan.id);

    if (planIndex < 0) {
      return;
    }

    const anchorIndex = this.plans[planIndex].anchors
      .findIndex(x => x.id === ref.markerRef.id);

    if (anchorIndex < 0) {
      return;
    }

    const imageIndex = this.plans[planIndex].anchors[anchorIndex].images
      .findIndex(x => x.id === ref.imageMarkerRef.imageRef.id);

    if (imageIndex < 0) {
      return;
    }

    if (!this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers) {
      this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers = [];
    }

    this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers.push(marker);

    this.selectedPlan = this.plans[planIndex];
  }

  private _updateSitePlanImageMarker(
    ref: planViewerModels.PlanViewerImageMarkerRef,
    marker: models.IPlanAnchorImageMarkerViewModel,
  ): void {
    if (!this.selectedPlan) {
      return;
    }

    const planIndex = this.plans
      .findIndex(x => x.id === this.selectedPlan.id);

    if (planIndex < 0) {
      return;
    }

    const anchorIndex = this.plans[planIndex].anchors
      .findIndex(x => x.id === ref.markerRef.id);

    if (anchorIndex < 0) {
      return;
    }

    const imageIndex = this.plans[planIndex].anchors[anchorIndex].images
      .findIndex(x => x.id === ref.imageMarkerRef.imageRef.id);

    if (imageIndex < 0) {
      return;
    }

    if (!this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers) {
      return;
    }

    const markerIndex = this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers
      .findIndex(x => x.id === ref.imageMarkerRef.id);

    if (markerIndex < 0) {
      return;
    }

    this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers[markerIndex] = marker;

    this.selectedPlan = this.plans[planIndex];
  }

  private _deleteSitePlanImageMarker(ref: planViewerModels.PlanViewerImageMarkerRef): void {
    if (!this.selectedPlan) {
      return;
    }

    const planIndex = this.plans
      .findIndex(x => x.id === this.selectedPlan.id);

    if (planIndex < 0) {
      return;
    }

    const anchorIndex = this.plans[planIndex].anchors
      .findIndex(x => x.id === ref.markerRef.id);

    if (anchorIndex < 0) {
      return;
    }

    const imageIndex = this.plans[planIndex].anchors[anchorIndex].images
      .findIndex(x => x.id === ref.imageMarkerRef.imageRef.id);

    if (imageIndex < 0) {
      return;
    }

    if (!this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers) {
      this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers = [];
    }

    const markerIndex = this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers
      .findIndex(x => x.id === ref.imageMarkerRef.id);

    if (0 <= markerIndex) {
      this.plans[planIndex].anchors[anchorIndex].images[imageIndex].markers.splice(markerIndex, 1);
    }

    this.selectedPlan = this.plans[planIndex];
  }
}
