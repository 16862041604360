import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { alert } from 'devextreme/ui/dialog';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Tools } from '../../../../shared/tools';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { InvitationService } from '../../services/invitation.service';

import * as models from '../../../../shared/models/generated';

import { environment } from '../../../../../environments/environment';

@Component({
  templateUrl: 'invite-user-dialog.component.html',
  styleUrls: ['invite-user-dialog.component.scss'],
})
export class InviteUserDialogComponent implements OnInit, OnDestroy {
  private static readonly _usersUrl = `${environment.adminUrl}/User/Get`;
  private static readonly _leasesUrl = `${environment.adminUrl}/Leases/shortLeaseModels?desc=true`;
  private static readonly _companiesUrl = `${environment.adminUrl}/Company`;
  private static readonly _claimsUrl = `${environment.adminUrl}/stateraClaim`;
  private static readonly _claimGroupsUrl = `${environment.adminUrl}/stateraClaimGroup`;

  userDataSource: CustomStore;
  leaseDataSource: CustomStore;
  companyDataSource: CustomStore;
  claimDataSource: CustomStore;
  claimGroupDataSource: CustomStore;
  leaseUserRolesDataSource: DataSource;

  searchableUserFields: Array<string>;

  userInvitation: models.IUserInvitationViewModel;

  Tools = Tools;

  readonly reloadUsersList: () => Promise<unknown>;

  private readonly _popupRefService: PopupRefService;
  private readonly _invitationService: InvitationService;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _destroy$: Subject<void>;

  constructor(popupRefService: PopupRefService, invitationService: InvitationService, changeDetectorRef: ChangeDetectorRef) {
    this._popupRefService = popupRefService;
    this._invitationService = invitationService;
    this._changeDetectorRef = changeDetectorRef;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    const isRequestInjected = !!this.userInvitation;

    this.userInvitation = this.userInvitation || <models.IUserInvitationViewModel>{};

    this.userDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: InviteUserDialogComponent._usersUrl,
    });

    this.leaseDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: InviteUserDialogComponent._leasesUrl,
    });

    this.companyDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: InviteUserDialogComponent._companiesUrl,
    });

    this.claimDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: InviteUserDialogComponent._claimsUrl,
    });

    this.claimGroupDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: InviteUserDialogComponent._claimGroupsUrl,
    });

    this.leaseUserRolesDataSource = new DataSource({
      key: 'id',
      store: Tools.EnumToArray(models.LeaseUserRole),
    });

    this.searchableUserFields = [
      'firstName',
      'lastName',
      'company.name',
    ];

    if (isRequestInjected) {
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleLeaseChange(leaseId: number): void {
    this.userInvitation.relatedToCompanyId = null;

    this.companyDataSource = AspNetData.createStore({
      loadUrl: `${InviteUserDialogComponent._companiesUrl}?leaseId=${leaseId}`,
    });
  }

  handleRoleChange(role: models.LeaseUserRole): void {
    this.claimGroupDataSource
      .load()
      .then((claimGroups) => {
        if (!claimGroups) {
          return;
        }

        this.userInvitation.claimGroupIds = claimGroups
          .filter(x => x.name.replace('-', '').replace(/\s+/gi, '').toLowerCase() === models.LeaseUserRole[role].toLowerCase())
          .map(x => x.id);

        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
      });
  }

  claimDisplayExpression(claim: models.IStateraClaim): string {
    if (!claim) {
      return '';
    }

    const claimType = models.StateraClaimTypeAsEnum[claim.claimType];
    const claimValue = models.StateraClaimValueAsEnum[claim.claimValue];

    return [claimType, claimValue]
      .filter(Boolean)
      .join(': ');
  }

  claimSelectionChanged(selectedItems): void {
    if (!selectedItems || !selectedItems.selectedRowsData) {
      return;
    }

    this.userInvitation.claimIds = selectedItems.selectedRowsData.map(x => x.id);
  }

  claimGroupSelectionChanged(selectedItems): void {
    if (!selectedItems || !selectedItems.selectedRowsData) {
      return;
    }

    this.userInvitation.claimGroupIds = selectedItems.selectedRowsData.map(x => x.id);
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this._invitationService
      .inviteExistingUser({...this.userInvitation})
      .pipe(
        tap(() => {
          this._popupRefService.hide();

          if (this.reloadUsersList) {
            this.reloadUsersList();
          }
        }),
        catchError(error => {
          if (error) {
            alert(error.error, 'Error');
          }

          return of(error);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  cancel(): void {
    this._popupRefService.hide();
  }
}
