import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import CustomStore from 'devextreme/data/custom_store';

import { Tools } from '../../../../shared/tools';
import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

import { PopupService } from '../../../infrastructure/services/popup.service';

import { environment } from '../../../../../environments/environment';

import * as models from '../../../../shared/models/generated';

import { InviteUserDialogComponent } from '../invite-user-dialog/invite-user-dialog.component';

@Component({
  templateUrl: 'user-invitation-requests.component.html',
  styleUrls: ['user-invitation-requests.component.scss'],
})
export class UserInvitationRequestsComponent implements OnInit {
  private static readonly _userInvitationRequestListUrl = `${environment.adminUrl}/invitation/user-requests`;
  private static readonly _usersUrl = `${environment.adminUrl}/User/Get`;
  private static readonly _leasesUrl = `${environment.adminUrl}/Leases/shortLeaseModels`;
  private static readonly _claimGroupsUrl = `${environment.adminUrl}/stateraClaimGroup`;

  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;

  dataSource: CustomStore;
  userDataSource: CustomStore;
  leaseDataSource: CustomStore;

  Tools = Tools;

  requestStatuses = Tools.EnumToArray(models.UserInvitationRequestStatus);

  private readonly _popupService: PopupService;

  constructor(popupService: PopupService) {
    this._popupService = popupService;

    this.isInviteButtonVisible = this.isInviteButtonVisible.bind(this);
    this.isResendInvitationButtonVisible = this.isResendInvitationButtonVisible.bind(this);
    this.isDeleteButtonVisible = this.isDeleteButtonVisible.bind(this);
    this.acceptUserInvitationRequest = this.acceptUserInvitationRequest.bind(this);
    this.resendUserInvitationRequest = this.resendUserInvitationRequest.bind(this);
    this.deleteUserInvitationRequest = this.deleteUserInvitationRequest.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: UserInvitationRequestsComponent._userInvitationRequestListUrl,
      updateUrl: UserInvitationRequestsComponent._userInvitationRequestListUrl,
      deleteUrl: UserInvitationRequestsComponent._userInvitationRequestListUrl,
    });

    this.userDataSource = AspNetData.createStore({
      loadUrl: UserInvitationRequestsComponent._usersUrl,
    });

    this.leaseDataSource = AspNetData.createStore({
      loadUrl: UserInvitationRequestsComponent._leasesUrl,
    });
  }

  onToolbarPreparing(event): void {
    if (event.toolbarOptions) {
      event.toolbarOptions.items = [
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'leaseAbstractImportTool',
          options: {
            icon: 'fa fa-user-plus',
            disabled: false,
            text: 'Invite',
            onClick: () => this._showInviteUserDialog(null),
          },
          showText: 'inMenu',
          sortIndex: 21,
          widget: 'dxButton',
        },
        ...(event.toolbarOptions.items || [])
      ];
    }
  }

  getRequestedByUserAvatar(item): string {
    if (!item || !item.data || !item.data.requestedBy || !item.data.requestedBy.profile || !item.data.requestedBy.profile.avatar) {
      return 'assets/img/avatar.png';
    }

    return item.data.requestedBy.profile.avatar.url;
  }

  getRequestedByUserDisplayName(item): string {
    if (!item || !item.data || !item.data.requestedBy || !item.data.requestedBy.profile) {
      return '';
    }

    const { firstName, lastName } = item.data.requestedBy.profile;

    return [firstName, lastName]
      .filter(Boolean)
      .join(' ');
  }

  isInviteButtonVisible(event): boolean {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    return event.row.data.requestStatus === models.UserInvitationRequestStatus.Pending;
  }

  isResendInvitationButtonVisible(event): boolean {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    return (
      event.row.data.requestStatus !== models.UserInvitationRequestStatus.Pending &&
      event.row.data.requestStatus !== models.UserInvitationRequestStatus.Accepted
    );
  }

  isDeleteButtonVisible(event): boolean {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    return event.row.data.requestStatus !== models.UserInvitationRequestStatus.Accepted;
  }

  acceptUserInvitationRequest(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    const claimGroupsDataStore = AspNetData.createStore({
      key: 'id',
      loadUrl: UserInvitationRequestsComponent._claimGroupsUrl,
    });

    const preparedUserInvitation = this.convertRequestToInvitation(event.row.data);

    const showDialog = (userInvitation: models.IUserInvitationViewModel) => {
      this._popupService.show(InviteUserDialogComponent, {
        title: 'Invite user',
        width: 750,
        maxHeight: '90%',
        showCloseButton: true,
        closeOnOutsideClick: false,
        injectableData: {
          userInvitation: userInvitation,
          reloadUsersList: async () => {
            if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
              const dataSource = this.dxDataGridComponent.instance.getDataSource();
              await dataSource.reload();
            }
          },
        }
      });
    };

    claimGroupsDataStore
      .load()
      .then(claimGroups => {
        preparedUserInvitation.claimGroupIds = claimGroups
          .filter(x => {
            const name = x.name.replace('-', '').replace(/\s+/gi, '').toLowerCase();
            const role = models.LeaseUserRole[preparedUserInvitation.leaseUserRole].toLowerCase();

            return name === role;
          })
          .map(x => x.id);

        showDialog(preparedUserInvitation);
      })
      .catch(() => showDialog(preparedUserInvitation));
  }

  resendUserInvitationRequest(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to send the invitation again?',
        acceptFn: () => {
          this.dataSource
            .update(event.row.data.id, null)
            .then(() => {
              if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
                const dataSource = this.dxDataGridComponent.instance.getDataSource();
                dataSource.reload();
              }
            });
        },
      },
    });
  }

  deleteUserInvitationRequest(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to delete this record?',
        acceptFn: () => {
          this.dataSource
            .remove(event.row.data.id)
            .then(() => {
              if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
                const dataSource = this.dxDataGridComponent.instance.getDataSource();
                dataSource.reload();
              }
            });
        },
      },
    });
  }

  convertRequestToInvitation(invitationRequest: models.IUserInvitationRequest): models.IUserInvitationViewModel {
    return <models.IUserInvitationViewModel>{
      userInvitationRequestId: invitationRequest.id,
      leaseId: invitationRequest.leaseId,
      leaseUserRole: models.LeaseUserRole[invitationRequest.role],
      invitedByUserId: invitationRequest.requestedById,
      relatedToCompanyId: invitationRequest.requestedBy.companyId,
    };
  }

  getRequestStatus(userInvitationRequestStatus: models.UserInvitationRequestStatus): string {
    switch (userInvitationRequestStatus) {
      case models.UserInvitationRequestStatus.Pending:
        return 'Pending invite';

      case models.UserInvitationRequestStatus.Accepted:
        return 'Accepted';

      case models.UserInvitationRequestStatus.UserAcceptance:
      case models.UserInvitationRequestStatus.UserAcceptanceReadTracked:
      case models.UserInvitationRequestStatus.UserAcceptanceClickTracked:
        return 'Pending user acceptance';

      default:
        return null;
    }
  }

  getRequestStatusDetails(userInvitationRequestStatus: models.UserInvitationRequestStatus): string {
    switch (userInvitationRequestStatus) {
      case models.UserInvitationRequestStatus.UserAcceptance:
        return 'Invitation not read';

      case models.UserInvitationRequestStatus.UserAcceptanceReadTracked:
        return 'Invitation read';

      case models.UserInvitationRequestStatus.UserAcceptanceClickTracked:
        return 'Link clicked';

      default:
        return null;
    }
  }

  private _showInviteUserDialog(userInvitation: models.IUserInvitationViewModel): void {
    this._popupService.show(InviteUserDialogComponent, {
      title: 'Invite user',
      width: 750,
      maxHeight: '90%',
      showCloseButton: true,
      closeOnOutsideClick: false,
      injectableData: {
        userInvitation: userInvitation,
        reloadUsersList: async () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            await dataSource.reload();
          }
        },
      }
    });
  }
}
