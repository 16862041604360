import {animate, style, transition, trigger} from '@angular/animations';
import * as ng from '@angular/core';
import {Component, EventEmitter, HostListener, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

import {ImageViewerService} from '../../../image-viewer/services/image-viewer.service';
import {ImageViewerRefService} from '../../../image-viewer/services/image-viewer-ref.service';

import {PlanViewerMarkerPopoverOptions} from '../../models/plan-viewer-marker-popover-options.model';

import * as imageViewerModels from '../../../image-viewer/models/image-viewer-image.model';
import * as planViewerModels from '../../models/plan-viewer.model';
import {BuildingAttachmentType} from "../../../../shared/models/generated";

type EventHandler = EventEmitter<planViewerModels.PlanViewerImageMarkerRef>;

@Component({
  templateUrl: 'plan-viewer-marker-popover.component.html',
  styleUrls: ['plan-viewer-marker-popover.component.scss'],
  animations: [
    trigger('popoverAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.15s ease-in', style({'opacity': '1'}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('0.15s ease-out', style({'opacity': '0'}))
      ])
    ])
  ],
})
export class PlanViewerMarkerPopoverComponent implements ng.AfterViewInit, ng.OnDestroy {
  @ViewChild('popoverContainerElementRef', {static: false}) popoverContainerElementRef: ng.ElementRef;

  readonly injectableData: { marker: planViewerModels.PlanViewerMarkerRef };

  readonly mouseIn$: Subject<void>;
  readonly mouseOut$: Subject<void>;

  markerCreated$: EventHandler;
  markerChanged$: EventHandler;
  markerDeleted$: EventHandler;

  private _imageViewerRef: ImageViewerRefService;

  private readonly _renderer: ng.Renderer2;
  private readonly _options: PlanViewerMarkerPopoverOptions;
  private readonly _imageViewerService: ImageViewerService;

  private readonly _destroy$: Subject<void>;

  constructor(renderer: ng.Renderer2, options: PlanViewerMarkerPopoverOptions, imageViewerService: ImageViewerService) {
    this._renderer = renderer;
    this._options = options;
    this._imageViewerService = imageViewerService;

    this._destroy$ = new Subject<void>();

    this.mouseIn$ = new Subject<void>();
    this.mouseOut$ = new Subject<void>();
  }

  ngAfterViewInit(): void {
    if (!this.popoverContainerElementRef || !this.popoverContainerElementRef.nativeElement || !this._options) {
      return;
    }

    const containerElement = this.popoverContainerElementRef.nativeElement;

    const containerElementWidth = containerElement.offsetWidth;
    const containerElementHeight = containerElement.offsetHeight;

    let positionX = (this._options.x || 0) + window.pageXOffset;
    let positionY = (this._options.y || 0) + window.pageYOffset;

    const positionFreeSpace = [
      {
        name: 'top',
        value: (this._options.y || 0) - (this._options.markerHeight || 0) - containerElementHeight - 16,
      },
      {
        name: 'right',
        value: window.innerWidth - ((this._options.x || 0) + ((this._options.markerWidth || 0) / 2) + containerElementWidth + 16),
      },
      {
        name: 'bottom',
        value: window.innerHeight - ((this._options.y || 0) + containerElementHeight + 16),
      },
      {
        name: 'left',
        value: (this._options.x || 0) - ((this._options.markerWidth || 0) / 2) - containerElementWidth - 16,
      },
    ];

    const positionWithMostFreeSpace = positionFreeSpace
      .reduce((acc, cur) => {
        return (acc.value > cur.value) ? acc : cur;
      });

    if (positionWithMostFreeSpace.value < 0) {
      positionX = window.pageXOffset + (window.innerWidth / 2) - (containerElementWidth / 2);
      positionY = window.pageYOffset + (window.innerHeight / 2) - (containerElementHeight / 2);

      this._renderer.addClass(containerElement, 'center');
    } else {
      switch (positionWithMostFreeSpace.name) {
        case 'top':
          positionX = positionX - (containerElementWidth / 2);
          positionY = positionY - (this._options.markerHeight || 0) - containerElementHeight;
          break;
        case 'right':
          positionX = positionX + ((this._options.markerWidth || 0) / 2);
          positionY = positionY - ((this._options.markerHeight || 0) / 2) - (containerElementHeight / 2);
          break;
        case 'bottom':
          positionX = positionX - (containerElementWidth / 2);
          positionY = positionY;
          break;
        case 'left':
          positionX = positionX - ((this._options.markerWidth || 0) / 2) - containerElementWidth;
          positionY = positionY - ((this._options.markerHeight || 0) / 2) - (containerElementHeight / 2);
          break;
      }

      if (positionX < 8) {
        positionX = 8;
      }

      if (positionX - window.pageXOffset + containerElementWidth + 8 > window.innerWidth) {
        positionX -= (positionX - window.pageXOffset + containerElementWidth + 8) - window.innerWidth;
      }

      if (positionY < 8) {
        positionY = 8;
      }

      if (positionY - window.pageYOffset + containerElementHeight + 8 > window.innerHeight) {
        positionY -= (positionY - window.pageYOffset + containerElementHeight + 8) - window.innerHeight;
      }

      this._renderer.addClass(containerElement, positionWithMostFreeSpace.name);
    }

    this._renderer.setStyle(containerElement, 'transform', `translate(${positionX}px, ${positionY}px)`);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();

    this.mouseIn$.complete();
    this.mouseOut$.complete();
  }

  getImages(): Array<imageViewerModels.ImageViewerImage> {
    if (!this.injectableData || !this.injectableData.marker) {
      return null;
    }

    const marker = this.injectableData.marker;

    if (marker.buildingUnit || marker.buildingUnitId) {
      if (marker.buildingUnit && marker.buildingUnit.attachments && marker.buildingUnit.attachments.length) {
        return marker
          .buildingUnit
          .attachments.filter(x => x.buildingAttachmentType === BuildingAttachmentType.Picture)
          .map(image => ({
            id: image.id,
            imageUrl: image.file.url,
            imageKind: imageViewerModels.ImageViewerImageKind.Standard,
          }));
      }
    } else {
      if (marker.images && marker.images.length) {
        return marker.images.map(image => image.imageViewerImage);
      }
    }

    return null;
  }

  setupHandlers(createHandler: EventHandler, changeHandler: EventHandler, deleteHandler: EventHandler): void {
    this.markerCreated$ = createHandler || new EventEmitter<planViewerModels.PlanViewerImageMarkerRef>();
    this.markerChanged$ = changeHandler || new EventEmitter<planViewerModels.PlanViewerImageMarkerRef>();
    this.markerDeleted$ = deleteHandler || new EventEmitter<planViewerModels.PlanViewerImageMarkerRef>();
  }

  handleZoomClick(imageRef: imageViewerModels.ImageViewerImageRef): void {
    if (!this.injectableData || !this.injectableData.marker || !this._options || !imageRef) {
      return;
    }

    this._showImageViewer(imageRef);
  }

  @HostListener('mouseenter', ['$event'])
  handleMouseEnter(): void {
    if (!this.mouseIn$) {
      return;
    }

    this.mouseIn$.next();
  }

  @HostListener('mouseleave', ['$event'])
  handleMouseLeave(): void {
    if (!this.mouseOut$) {
      return;
    }

    this.mouseOut$.next();
  }

  private _showImageViewer(imageRef: imageViewerModels.ImageViewerImageRef): void {
    if (this._imageViewerRef) {
      return;
    }

    this._imageViewerRef = this._imageViewerService.show(
      this.getImages() || [],
      {
        width: '95%',
        height: '95%',
        maxWidth: 1800,
        closeOnOutsideClick: false,
        showCloseButton: true,
        title: this.injectableData.marker.title,
        activeIndex: imageRef.index,
        allowChangeMarkers: this._options.allowChangeMarkers && !this.injectableData.marker.buildingUnit,
        enableArrowNavigation: false,
      },
    );

    this._imageViewerRef.onShowing
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this._hidePopover();
        }),
      )
      .subscribe();

    this._imageViewerRef.onHiding
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this._imageViewerRef = null;
        }),
      )
      .subscribe();

    this._imageViewerRef.onMarkerCreated
      .pipe(
        takeUntil(this._destroy$),
        tap((markerRef) => this._handleMarkerCreated(markerRef)),
      )
      .subscribe();

    this._imageViewerRef.onMarkerChanged
      .pipe(
        takeUntil(this._destroy$),
        tap((markerRef) => this._handleMarkerChanged(markerRef)),
      )
      .subscribe();

    this._imageViewerRef.onMarkerDeleted
      .pipe(
        takeUntil(this._destroy$),
        tap((markerRef) => this._handleMarkerDeleted(markerRef)),
      )
      .subscribe();
  }

  private _handleMarkerCreated(markerRef: imageViewerModels.ImageViewerImageMarkerRef): void {
    if (!this.markerCreated$ || !this.injectableData || !this.injectableData.marker || !markerRef) {
      return;
    }

    this.markerCreated$.emit({
      markerRef: this.injectableData.marker,
      imageMarkerRef: markerRef,
    });
  }

  private _handleMarkerChanged(markerRef: imageViewerModels.ImageViewerImageMarkerRef): void {
    if (!this.markerChanged$ || !this.injectableData || !this.injectableData.marker || !markerRef) {
      return;
    }

    this.markerChanged$.emit({
      markerRef: this.injectableData.marker,
      imageMarkerRef: markerRef,
    });
  }

  private _handleMarkerDeleted(markerRef: imageViewerModels.ImageViewerImageMarkerRef): void {
    if (!this.markerDeleted$ || !this.injectableData || !this.injectableData.marker || !markerRef) {
      return;
    }

    this.markerDeleted$.emit({
      markerRef: this.injectableData.marker,
      imageMarkerRef: markerRef,
    });
  }

  private _hidePopover(): void {
    if (!this._options || !this._options.planViewerMarkerPopupService) {
      return;
    }

    this._options.planViewerMarkerPopupService.hide();
  }
}
