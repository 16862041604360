<h2 class="content-block">Building Units</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              (onEditorPreparing)="onEditorPreparing($event)" (onEditingStart)="onEditingStart($event)"
              (onInitNewRow)="initNewRow($event)" (onRowPrepared)="onRowPrepared($event)">
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup></dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" dataType="number"
              [width]="70">
  </dxi-column>

  <dxi-column dataField="buildingId" caption="Building" cellTemplate="buildingFieldTemplate">
    <dxo-lookup [dataSource]="buildingDataSource" valueExpr="id" [displayExpr]="tools.buildingNameExpression">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'buildingFieldTemplate'" class="lookup-user-field">
    <div>{{ tools.buildingNameExpression(item.data.building) }}</div>
  </div>

  <dxi-column dataField="building.portfolio.id" caption="Portfolio">
    <dxo-lookup [dataSource]="portfolioDataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="building.portfolio.companyId" caption="Company">
    <dxo-lookup [dataSource]="companyDataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="name">
  </dxi-column>
  <dxi-column dataField="description">
  </dxi-column>
  <dxi-column dataField="size" editorType="dxNumberBox"
              [editorOptions]="{ min: 0, format: '#0 square feet', placeholder: '0 square feet' }">
  </dxi-column>

  <dxi-column dataField="totalOfficeArea" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0.### SF', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="totalDocksInterior" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="totalDocksExterior" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="amps" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0.##', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="volts" editorType="dxTextBox"></dxi-column>
  <dxi-column dataField="phase" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="columnSpacing" editorType="dxTextBox" [editorOptions]="{ mask: '#0\' x #0\'', useMaskedValue: true }"></dxi-column>
  <dxi-column dataField="clearHeight" editorType="dxNumberBox" [editorOptions]="{ min: 0, max: 999, format: '#0.## feet', placeholder: '0.0 feet' }"></dxi-column>
  <dxi-column dataField="driveInDoors" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="totalDriveInDoorsSize" editorType="dxTextBox"></dxi-column>
  <dxi-column dataField="sprinklerSystem">
    <dxo-lookup [dataSource]="sprinklerSystems" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="createdOn" dataType="date" caption="Created On" ></dxi-column>
  <div class="lookup-user-field"
       *dxTemplate="let item of 'buildingCellTemplate'">
    <img alt=""
         [src]="getBuildingPicture(item)" />
    <div>{{ getBuildingName(item) }}</div>
  </div>

  <dxi-column type="buttons">
    <dxi-button name="edit">
    </dxi-button>
    <dxi-button name="delete"
                [visible]="isEditButtonVisible">
    </dxi-button>
  </dxi-column>

  <dxo-editing mode="popup"
               [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup title="Add or edit Building Unit" width="95%"
               [showTitle]="true" [maxWidth]="600" [height]="450"
               [position]="{ my: 'center', at: 'center', of: _window }">
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item dataField="building.portfolio.companyId" [editorOptions]="companyControlOptions" isRequired="true"></dxi-item>
      <dxi-item dataField="building.portfolio.id" [editorOptions]="portfolioControlOptions" isRequired="true" #portfolioControl></dxi-item>
      <dxi-item dataField="buildingId" [editorOptions]="buildingControlOptions" isRequired="true" #buildingControl></dxi-item>
      <dxi-item dataField="name" isRequired="true"></dxi-item>
      <dxi-item dataField="description"></dxi-item>
      <dxi-item dataField="size"></dxi-item>
      <dxi-item dataField="totalOfficeArea"></dxi-item>
      <dxi-item dataField="totalDocksInterior"></dxi-item>
      <dxi-item dataField="totalDocksExterior"></dxi-item>
      <dxi-item dataField="amps"></dxi-item>
      <dxi-item dataField="volts"></dxi-item>
      <dxi-item dataField="phase"></dxi-item>
      <dxi-item dataField="columnSpacing"></dxi-item>
      <dxi-item dataField="clearHeight"></dxi-item>
      <dxi-item dataField="driveInDoors"></dxi-item>
      <dxi-item dataField="totalDriveInDoorsSize"></dxi-item>
      <dxi-item dataField="sprinklerSystem" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="createdOn" isRequired="true"
                [editorOptions]="{dateSerializationFormat: 'yyyy-MM-dd'}"></dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>
